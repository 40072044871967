import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image, Row, Col } from 'react-bootstrap';

const ImageGrid = ({ images }) => {
  return (
    <><Row className='mt-5'>
        <Col className='d-flex flex-column align-items-center mb-4'>
          <h3>Más que un equipo</h3>
        </Col>
    </Row>
    <Row className='mt-5'>
        {images.map((image, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className={`d-flex flex-column align-items-center mb-4 animate__animated ${index % 2 === 0 ? 'animate-fadeIn' : 'animate-fadeRight'}`}
            style={{ animationDelay: `${index * 0.2}s` }}>
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <Image
                src={image.src}
                height={150}
                width={150}
                alt={`Image ${index + 1}`}
                className="rounded-lg object-cover mb-2"
                aria-label={`${image.name}`} 
                loading="lazy"
                />
            </a>
            <p className="font-bold text-center">{image.name}</p>
            <p className="text-gray-400 text-center">{image.title}</p>
          </Col>
        ))}
      </Row></>
  );
};

export default ImageGrid;
