import React from 'react';

const SectionCookies = ({ title, children }) => {
  return (
    <div className='mt-3'>
      <h5 className='text-white'>{title}</h5>
      <div className='text-white'>{children}</div>
    </div>
  );
};

export default SectionCookies;
