import React, { useState, useEffect } from 'react';
import { FaMoon, FaSun, FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/App.css';


// aqui es donde está el boton para cambiar de modo
const Mode = () => {
    // Estado para verificar si el tema actual es oscuro
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const navigate = useNavigate(); // Hook de navegación

    // Función que cambia el tema y actualiza el estado
    const toggleTheme = () => {
      setIsDarkTheme(!isDarkTheme);
      document.body.classList.toggle('dark-theme');
    };
  
    // Efecto para manejar el cambio de tema en el montaje y actualización del componente
    useEffect(() => {
      const bodyClass = document.body.classList;   
      if (isDarkTheme) {
        bodyClass.add('dark-theme');       
      } else {
        bodyClass.remove('dark-theme');       
      }
    }, [isDarkTheme]);
    
  //aqui es donde se muestran todos los componentes construidos y cargados 
    return (   
      <div className="flex items-center justify-between p-2 rounded-full cursor-pointer">
      <button onClick={toggleTheme} className="mr-4" aria-label='mode'>
        {isDarkTheme ? (
          <FaSun className="text-yellow-500 text-2xl" />
        ) : (
          <FaMoon className="text-gray-700 text-2xl" />
        )}
      </button>
      <button className="ms-4" aria-label='mode' onClick={() => navigate('/')}>
      {isDarkTheme ? (
        <FaHome className="text-2xl text-white" />
        ) : (
          <FaHome className="text-2xl text-black" />
        )}       
      </button>
    </div>
    );
  };

  export default Mode;