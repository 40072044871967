import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './styles/App.css';
import './styles/output.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MyBody from './Portfolio';
import Mode from './components/Mode';
import FooterWeb from './Footer';
import CookieConsentBanner from './components/CookieBanner';
import PoliticaCookies from './PoliticaCookies';
import AvisoLegalPage from './AvisoLegal';
import PrivacidadPage from './PoliticaPrivacidad';

function App(){
  const [cookies, setCookie, removeCookie] = useCookies(['user-preference']);

  useEffect(() => {
    // Establecer una cookie cuando el componente se monta
    setCookie('user-preference', 'dark-mode', { path: '/', maxAge: 3600 });

    // Obtener el valor de la cookie
    console.log('User Preference:', cookies['user-preference']);
    
    // Borrar una cookie
    // removeCookie('user-preference');
  }, [setCookie, cookies]);
  return(       
    <Router>
      <Mode />
      <Routes>
        <Route path="/" element={<MyBody />} />
        <Route path="/diseno-web/politica-de-cookies" element={<PoliticaCookies />} />
        <Route path="/politica-de-privacidad" element={<PrivacidadPage />} />
        <Route path="/aviso-legal" element={<AvisoLegalPage />} />
        <Route path="/diseno-web-pontevedra" element={<MyBody />} />
        {/* Agrega otras rutas aquí según sea necesario */}
      </Routes>
      <CookieConsentBanner />
      <FooterWeb />
    </Router>  
  );
}

export default App;
