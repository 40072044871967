import React from 'react';
import './styles/App.css';
import './styles/output.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function FooterWeb(){
    const year = new Date().getFullYear();
  return(       
    <Container className='shadow-3d bg-gradient-blumine-diagonal rounded-lg mt-5 mb-5 p-2'>    
    <Row>    
      <div className='d-flex justify-content-evenly align-items-center mt-3'>
          <p className='text-white fw-bold'>
            <Link to="/aviso-legal" className="text-white text-decoration-none">Aviso Legal</Link>
          </p>
          <p className='text-white fw-bold'>
            <Link to="/diseno-web/politica-de-cookies" className="text-white text-decoration-none">Política de Cookies</Link>
          </p>
          <p className='text-white fw-bold'>
            <Link to="/politica-de-privacidad" className="text-white text-decoration-none">Política de Privacidad</Link>
          </p>
          <p className='text-white fw-bold'>
            <Link to="/mapa-del-sitio" className="text-white text-decoration-none">Mapa del Sitio</Link>
          </p>
      </div>      
      <p className='text-center mt-3 text-white fw-bold fs-6'><b>Copyrigth © {year} - <Link to="/" className="text-white text-decoration-none"> FilgueiraSolution </Link></b></p>    
    </Row>        
  </Container>
    
       
  );
}

export default FooterWeb;


