import React from 'react';
import ServiciosImg from '../assets/images/servicios.webp';
import ProyectosImg from '../assets/images/proyectos.webp';
import EquipoImg from '../assets/images/equipo.webp';
import '../styles/App.css';
import '../styles/output.css';

const ServiceCard = ({ image, title, animation }) => {
  return (
    <div className={`relative rounded-lg shadow-3d overflow-hidden shadow-lg w-full ${animation }`}>
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <div className="absolute inset-0  bg-gradient-blumine-services to-transparent"></div>
      <div className="absolute bottom-0 p-4 text-white">
        <h2 className="text-xl fs-1 lh-sm font-bold">{title}</h2>
      </div>
    </div>
  );
};

const Services = () => {
  return (
    <div className="container mx-auto mt-5 px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <ServiceCard image={ServiciosImg} title="Creando Oportunidades" animation="animate-fadeRight" />
        <ServiceCard image={ProyectosImg} title="Creciendo Juntos" animation="animate-fadeIn" />
        <ServiceCard image={EquipoImg} title="Profesionales Comprometidos"  animation="animate-fadeLeft" />
      </div>
    </div>
  );
};

export default Services;
