import React from 'react';
import './styles/App.css';
import './styles/output.css';
import { Container, Row } from 'react-bootstrap';
import SectionCookies from './components/SectionsCookies'; 

function AvisoLegalPage(){   
  return(       
    <Container className='shadow-3d bg-gradient-blumine-diagonal rounded-lg mt-5 mb-5 p-5'>
        <h3 className='text-white mb-4'>Aviso Legal</h3>
        <p><strong> Denominación Social:</strong> FilgueiraSolutions</p>
        <p><strong> Nombre Comercial:</strong> FilgueiraSolutions</p>
        <p><strong> Domicilio Social:</strong> Pontevedra</p>
        <p><strong> NIF:</strong> 60275207G</p>
        <p><strong> Teléfono:</strong> +34 695 77 16 72</p>
        <p><strong> Nombre de dominio:</strong> filgueirasolutions.com</p>
        <p><strong> E-Mail:</strong> info@filgueirasolutions.com</p>
        <Row>
            <SectionCookies title="Objeto">
                <p>El prestador, responsable del sitio web, pone a disposición de los usuarios el presente documento con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI-CE), así como informar a todos los usuarios del sitio web respecto a cuáles son las condiciones de uso del sitio web. Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualesquiera otra disposición legal que fuera de aplicación. El prestador se reserva el derecho a modificar cualquier tipo de información que pudiera aparecer en el sitio web, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente con la publicación en el sitio web del prestador.</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Responsabilidad">
                <p>El prestador se exime de cualquier tipo de responsabilidad derivada de la información publicada en su sitio web, siempre que esta información haya sido manipulada o introducida por un tercero ajeno al mismo. El sitio web del prestador puede utilizar cookies (pequeños archivos de información que el servidor envía al ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio. Las cookies utilizadas en el sitio web tienen, en todo caso, carácter temporal con la única finalidad de hacer más eficaz su transmisión ulterior y desaparecen al terminar la sesión del usuario. En ningún caso se utilizarán las cookies para recoger información de carácter personal.

                Desde el sitio web del cliente es posible que se redirija a contenidos de terceros sitios web. Dado que el prestador no puede controlar siempre los contenidos introducidos por los terceros en sus sitios web, éste no asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, el prestador manifiesta que procederá a la retirada inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el orden público, procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en conocimiento de las autoridades competentes el contenido en cuestión.

                El prestador no se hace responsable de la información y contenidos almacenados, a título enunciativo pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualesquiera otro medio que permita a terceros publicar contenidos de forma independiente en la página web del prestador. No obstante y en cumplimiento de lo dispuesto en el art. 11 y 16 de la LSSI-CE, el prestador se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad, y colaborando de forma activa en la retirada o en su caso bloqueo de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional, o internacional, derechos de terceros o la moral y el orden público. En caso de que el usuario considere que existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al administrador del sitio web.

                Este sitio web ha sido revisado y probado para que funcione correctamente. En principio, puede garantizarse el correcto funcionamiento los 365 días del año, 24 horas al día. No obstante, el prestador no descarta la posibilidad de que existan ciertos errores de programación, o que acontezcan causas de fuerza mayor, catástrofes naturales, huelgas, o circunstancias semejantes que hagan imposible el acceso a la página web.
                </p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Protección de datos personales">
                <p>FilgueiraSolutions titular del portal www.filgueirasolutions.com , informa a los usuarios del mismo, en adelante el “portal”, que FilgueiraSolutions es responsable de los tratamientos que se realicen mediante este portal, salvo que otra cosa se informe en el tratamiento de que se trate.

                FilgueiraSolutions respeta la legislación vigente en materia de protección de datos personales, la privacidad de los usuarios y el secreto y seguridad de los datos personales, de conformidad con lo establecido en la legislación aplicable en materia de protección de datos, en concreto, el Reglamento 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, adoptando para ello las medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados, habida cuenta del estado de la tecnología, la naturaleza de los datos y los riesgos a los que están expuestos.

                En concreto, se informa a los usuarios del portal que sus datos de carácter personal sólo podrán obtenerse para su tratamiento cuando sean adecuados, pertinentes y no excesivos en relación con el ámbito y las finalidades determinadas, explícitas y legítimas para las que se hayan obtenido.

                Cuando se recaben los datos personales a través del portal, se informará previamente al usuario, de forma clara e inequívoca, de los siguientes extremos:</p>         
                <ol className="list-decimal list-inside text-white">
                    <li className="mt-2">                                      
                        <ul className="list-disc list-inside ml-5">
                            <li><span>Existencia de un tratamiento de datos de carácter personal. La identidad y los datos de contacto del responsable del tratamiento de datos personales obtenidos, los datos de contacto del Delegado de Protección de Datos, si procede; los fines del tratamiento a que se destinan los datos personales y la base jurídica del tratamiento; los destinatarios o las categorías de destinatarios de los datos personales, en su caso. En su caso, la intención del responsable de transferir datos personales a un tercer país; el plazo o los criterios de conservación de la información; la existencia del derecho a solicitar al responsable del tratamiento el acceso a los datos personales relativos al interesado, y su rectificación o supresión, o la limitación de su tratamiento, el derecho a la portabilidad de los datos, o a oponerse al tratamiento. Asimismo, la manera de ejercitar los mencionados derechos. Cuando el tratamiento esté basado en el consentimiento del interesado, la existencia del derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada; Si el tratamiento no está basado en el consentimiento, su derecho a ejercer la oposición al tratamiento. el derecho a presentar una reclamación ante una autoridad de control; la existencia, si las hubiera, de decisiones automatizas, incluida la elaboración de perfiles y el ejercicio de derechos asociados a dicho tratamiento.</span></li>
                        </ul>
                    </li>                    
                </ol>
                <p>En particular, cuando se recaben datos de niños, el tratamiento únicamente se considerará lícito si el consentimiento lo dio o autorizó el titular de la patria potestad o tutela sobre el niño.</p>
                <p>Los datos proporcionados se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales. Los datos que se le solicitan resultan necesarios, de manera que de no facilitarlos no será posible la prestación del servicio requerido. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en FilgueiraSolutions estamos tratando sus datos personales por tanto tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar su supresión cuando los datos ya no sean necesarios, a la limitación de su tratamiento, o a oponerse al tratamiento, así como al derecho de la portabilidad de los datos.</p>
                <p>El usuario será el único responsable a causa de la cumplimentación de los formularios con datos falsos, inexactos, incompletos o no actualizados.</p>
                <p>Tiene derecho a presentar una reclamación ante la Autoridad de Control (Agencia Española de Protección de Datos www.aepd.es)</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Propiedad Intelectual e industrial">
                <p>El sitio web, incluyendo a título enunciativo pero no limitativo su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos son propiedad del prestador o en su caso dispone de licencia o autorización expresa por parte de los autores. Todos los contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual e industrial, así como inscritos en los registros públicos correspondientes.

                Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso, explotación, distribución y comercialización, requiere en todo caso de la autorización escrita previa por parte del prestador. Cualquier uso no autorizado previamente por parte del prestador será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.

                Los diseños, logotipos, texto y/o gráficos ajenos al prestador y que pudieran aparecer en el sitio web, pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible controversia que pudiera suscitarse respecto a los mismos. En todo caso, el prestador cuenta con la autorización expresa y previa por parte de los mismos.

                El prestador reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando su sola mención o aparición en el sitio web la existencia de derechos o responsabilidad alguna del prestador sobre los mismos, como tampoco respaldo, patrocinio o recomendación por parte del mismo.

                Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a través del siguiente correo electrónico: administracion@filgueirasolutions.com</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Ley Aplicable y Jurisdicción">
                <p>Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales de Pontevedra.</p>
            </SectionCookies>
        </Row>

    </Container>       
  );
}

export default AvisoLegalPage;


