import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import '../styles/App.css';

const FloatingButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button 
      aria-label='up top'
      onClick={scrollToTop}
      className="floating-btn">
      <FaArrowUp />
    </button>
  );
};

export default FloatingButton;
