import React from 'react';
import './styles/App.css';
import './styles/output.css';
import { Container, Row } from 'react-bootstrap';
import SectionCookies from './components/SectionsCookies'; 

function PrivacidadPage(){   
  return(       
    <Container className='shadow-3d bg-gradient-blumine-diagonal rounded-lg mt-5 mb-5 p-5'>
        <h3 className='text-white'>Política de Privacidad</h3>
        <Row>
            <SectionCookies title="Introducción">
                <p>En cumplimiento con la normativa europea de protección de datos, el presente documento detalla el uso de cookies en nuestro sitio web, así como los tipos de cookies que utilizamos y los propósitos para los cuales se emplean. Este documento también proporciona información sobre cómo los usuarios pueden gestionar sus preferencias de cookies.</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="¿Qué son las Cookies?">
                <p>Las cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita un sitio web. Estas cookies permiten al sitio web recordar sus acciones y preferencias (como inicio de sesión, idioma, tamaño de fuente y otras preferencias de visualización) durante un período de tiempo, para que no tenga que volver a configurarlas cada vez que vuelva al sitio o navegue de una página a otra.</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Tipos de Cookies Utilizadas">
                <ol className="list-decimal list-inside text-white">
                    <li className="mt-2">
                        <b>Cookies Funcionales</b>                        
                        <ul className="list-disc list-inside ml-5">
                            <li><span className='fw-bold'>Descripción:</span> Estas cookies son esenciales para el correcto funcionamiento del sitio web. Permiten la navegación a través de las diferentes secciones del sitio y el uso de las diferentes opciones o servicios que en él existen, como el acceso a áreas restringidas, la realización del proceso de compra o la gestión del pago.</li>
                            <li><span className='fw-bold'>Propósito:</span> Mejorar la funcionalidad del sitio web y proporcionar servicios y funcionalidades específicos a los usuarios.</li>
                            <li><span className='fw-bold'>Duración:</span> Algunas de estas cookies son temporales y solo permanecen en su dispositivo mientras navega por el sitio web (cookies de sesión). Otras son persistentes y permanecen en su dispositivo durante un período más largo.</li>
                        </ul>
                    </li>
                    <li className="mt-2">
                        <b>Cookies de Estadísticas</b>                        
                        <ul className="list-disc list-inside ml-5">
                            <li><span className='fw-bold'>Descripción:</span> Estas cookies recopilan información sobre cómo los visitantes usan el sitio web, por ejemplo, qué páginas visitan con más frecuencia y si reciben mensajes de error de las páginas web.</li>
                            <li><span className='fw-bold'>Propósito:</span> Utilizamos esta información para mejorar la funcionalidad de nuestro sitio web y entender mejor cómo interactúan los usuarios con él.</li>
                            <li><span className='fw-bold'>Duración: </span>Estas cookies suelen ser persistentes y pueden permanecer en su dispositivo por un tiempo definido.</li>
                        </ul>
                    </li>
                    <li className="mt-2">
                        <b>Cookies de Marketing</b>                        
                        <ul className="list-disc list-inside ml-5">
                            <li><span className='fw-bold'>Descripción: </span>Estas cookies se utilizan para rastrear a los visitantes en los sitios web. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para los editores y terceros anunciantes.</li>
                            <li><span className='fw-bold'>Propósito: </span>Personalizar la experiencia publicitaria y mostrar anuncios relevantes en función de sus intereses.</li>
                            <li><span className='fw-bold'>Duración: </span>Estas cookies pueden ser de sesión o persistentes y permanecen en su dispositivo por un tiempo definido.</li>
                        </ul>
                    </li>
                </ol>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Gestión de Cookies">
                <p>Usted puede controlar y/o eliminar las cookies como desee. Puede eliminar todas las cookies que ya están en su computadora y configurar la mayoría de los navegadores para que no se instalen. Sin embargo, si lo hace, es posible que tenga que ajustar manualmente algunas preferencias cada vez que visite un sitio y que algunos servicios y funcionalidades no funcionen.</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Consentimiento">
                <p>Al utilizar nuestro sitio web, usted acepta el uso de cookies de acuerdo con este documento. Si no está de acuerdo con el uso de estas cookies, puede desactivarlas siguiendo las instrucciones proporcionadas en este documento para su navegador, pero tenga en cuenta que esto puede afectar su experiencia de usuario.</p>
            </SectionCookies>
        </Row>

        <Row>
            <SectionCookies title="Contacto">
                <p>Si tiene alguna pregunta sobre nuestra política de cookies, puede ponerse en contacto con nosotros a través de la información de contacto proporcionada en nuestro sitio web.</p>
            </SectionCookies>
        </Row>

    </Container>  
       
  );
}

export default PrivacidadPage;


