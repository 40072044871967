import React from 'react';
import Noslen from "./assets/images/team/NoslenFilgueiras.webp";
import Mercedes from "./assets/images/team/mercedes.webp";
import Borja from "./assets/images/team/borja.webp";
import Damian from "./assets/images/team/damian.webp";
import Logo from "./assets/images/darkLogo.png";
import { BsAward , BsChatQuote, BsBadgeAdFill  , BsBarChartFill, BsUiChecks, BsFileText } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { DiAndroid, DiApple, DiReact, DiChrome, DiWordpress, DiWindows } from "react-icons/di";
import { SlClock, SlEnvolope, SlCallOut } from "react-icons/sl";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import "./styles/App.css";
import "./styles/output.css";
import FloatingButton from "./components/FloatingButton";
import ServicesCard from "./components/ServicesCards";
import ImageGrid from "./components/Team";

const MyBody = () => {
  const images = [
    {
      src: Noslen,
      name: "Noslen Filgueiras",
      title: "Ingeniero Informático",
      link: "https://www.linkedin.com/in/noslen-filgueiras-trujillo/",
    },
    {
      src: Mercedes,
      name: "Mercedes Castillo",
      title: "Máster en Marketing Digital",
      link: "https://www.linkedin.com/in/mercedes-castillo-garc%C3%ADa-841b926a/",
    },
    {
      src: Borja,
      name: "Borja Soliño",
      title: "Desarrollador Full-Stack",
      link: "https://www.linkedin.com/in/francisco-borja-soli%C3%B1o-bastos-337aaa11a/",
    },
    {
      src: Damian,
      name: "Damian Perez",
      title: "Desarrollador Full-Stack",
      link: "https://www.linkedin.com/in/dami%C3%A1n-p%C3%A9rez-alonso-798974136/",
    },
  ];
  
  const handleWhatsAppRedirect = () => {
    window.location.href = 'https://wa.me/34695771672';
  };
  return (
    <>
      <Container className="mt-5 shadow-3d bg-gradient-blumine-diagonal bg-opacity-50   flex items-center justify-center rounded-lg p-3 ">
        <Row>
          <Col md={3}>
            <Image
              className="rounded-lg mb-3"
              alt="Figueiras Solutions CEO"
              height={150}
              width={150}
              src={Logo}
              loading="lazy"
            />
            <h2 className="fw-bold mb-1 text-white fs-5">
              Filgueiras Solutions
            </h2>
            <div className="bg-gradient-blumine-diagonal rounded-lg">
              <h1 className="p-2 text-sm text-white fs-6">
                Diseño y Desarrollo de Aplicaciones
              </h1>
            </div>
          </Col>
          <Col md={9} className="p-3">
            <h2 className="text-white">Somos algo más que desarrollo web</h2>
            <p className="text-white">
              Somos un equipo especializado en{" "}
              <span className="fw-bold">desarrollo web </span>,{" "}
              <span className="fw-bold">
                desarrollo de aplicaciones y marketing digital
              </span>
              , dedicada a transformar ideas en soluciones innovadoras. Nuestro
              equipo se especializa en la{" "}
              <span className="fw-bold">
                creación de sitios web, aplicaciones móviles intuitivas y
                estrategias de marketing digital{" "}
              </span>
              personalizadas para impulsar el crecimiento de tu negocio.
              Ofrecemos servicios de
              <span className="fw-bold">
                {" "}
                SEO, gestión de redes sociales, y campañas publicitarias{" "}
              </span>
              . Con una combinación de tecnologías de punta y mucha creatividad,
              te ayudamos a destacar en un mercado competitivo y a alcanzar tus
              objetivos empresariales.
            </p>
          </Col>
        </Row>
      </Container>
      <ServicesCard></ServicesCard>
      <Container className="mt-5 lg:w-9/12">
        <Row>
          <Col md={3}>
            <Card className="shadow-3d rounded-lg bg-gradient-blumine-diagonal pb-4">
              <Card.Body>
                <div className="d-flex justify-content-around my-3">
                  <Button
                    className="p-2 facebook bg-gradient-blumine-21"
                    name="facebook"
                    variant="light"
                    href="https://www.facebook.com/filgueirasolutions/"
                    aria-label='facebook'
                  >
                    {" "}
                    <FaFacebookF size={20} />
                  </Button>
                  <Button
                    className="p-2 youtube bg-gradient-blumine-21"
                    name="youtube"
                    variant="light"
                    href="https://www.youtube.com/channel/UC8RSilzt5_Gtm9Dk3l0GgGw/about"
                    aria-label='youtube'
                  >
                    {" "}
                    <FaYoutube size={20} />
                  </Button>
                  <Button
                    className="p-2 instagram bg-gradient-blumine-21"
                    name="instagram"
                    variant="light"
                    href="https://filgueirasolutions.com"
                    aria-label='instagram'
                  >
                    {" "}
                    <FaInstagram size={20} />
                  </Button>
                  <Button
                    className="p-2 linkedin bg-gradient-blumine-21"
                    name="linkedin"
                    variant="light"
                    href="https://www.linkedin.com/in/noslen-filgueiras-trujillo/"
                    aria-label='linkedin'
                  >
                    {" "}
                    <FaLinkedinIn size={20} />
                  </Button>
                  <Button
                    className="p-2 whatsapp bg-gradient-blumine-21"
                    name="whatsapp"
                    variant="light"
                    href="https://wa.me/34695771672"
                    aria-label='whatsapp'
                  >
                    {" "}
                    <FaWhatsapp size={20} />
                  </Button>
                </div>
                <div className="rounded-lg shadow-3d">
                  <div className="text-start shadow-3d p-4 rounded-lg">
                    <Stack gap={3}>
                      <div className="mt-4">
                        <button
                          name="telefono"
                          className="p-3 telefono bg-blumine-400 hover:bg-blumine-500 rounded-md"
                          href="tel:695771672"
                          aria-label='telefono'
                        >
                          {" "}
                          <SlCallOut color="white" size={18} />{" "}
                        </button>{" "}
                        <span className="text-sm text-white">
                          {" "}
                          +34 695 771 672
                        </span>{" "}
                      </div>
                      <div className="mt-4">
                        <button
                          name="email"
                          className="p-3 email bg-blumine-400 hover:bg-blumine-500 rounded-md"
                          href="mailto:info@filgueirasolution.com"
                          aria-label='email'
                        >
                          {" "}
                          <SlEnvolope color="white" size={18} />{" "}
                        </button>{" "}
                        <span className="text-sm text-white">
                          {" "}
                          info@filgueirasolution.com
                        </span>{" "}
                      </div>
                      <div className="mt-4">
                        <button
                          name="horario"
                          className="p-3 horario bg-blumine-400 hover:bg-blumine-500 rounded-md"
                          aria-label='horario'
                        >
                          {" "}
                          <SlClock color="white" size={18} />{" "}
                        </button>{" "}
                        <span className="text-sm text-wrap text-white">
                          Lunes-Viernes 9:00 a 20:00
                        </span>{" "}
                      </div>
                    </Stack>
                  </div>
                </div>
                 <button onClick={handleWhatsAppRedirect} aria-label='contacta'   className='mt-5 p-3 rounded-md text-white w-100 bg-blumine-950 border hover:bg-blumine-500'>Contacta con FilgueiraSolution</button> 
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Card className="shadow-3d rounded-lg bg-gradient-blumine-diagonal">
              <Card.Body>
                <h2 className="text-white">Servicios</h2>
                <p className="text-white">
                  En nuestra empresa, nos dedicamos a proporcionar soluciones
                  innovadoras y personalizadas en el ámbito de la tecnología y
                  el marketing digital. Entendemos que cada negocio tiene
                  necesidades únicas, por lo que ofrecemos una gama de servicios
                  diseñados para impulsar su presencia en internet y alcanzar
                  sus objetivos comerciales. A continuación, presentamos un
                  resumen de los principales servicios que ofrecemos.
                </p>
                <Row className="mt-4">
                  <Col md={6}>
                    <Card className="mb-3 bg-gradient-blumine-21  transition-transform transform hover:animate-tilt">
                      <Card.Body>
                        <h2 className="text-white">Diseño Web</h2>
                        <p className="text-white bg-white-transparent-01 p-2 rounded-lg">
                          Creamos sitios web profesionales, intuitivos y
                          visualmente impactantes, optimizados para ofrecer la
                          mejor experiencia de usuario, utilizando las últimas
                          tecnologías y tendencias en diseño web.
                        </p>
                        <div className="d-flex d-inline-block">
                          <DiChrome color="white" size={30}/>
                          <DiReact color="white" size={30}/>
                          <DiWordpress color="white" size={30}/>
                        </div>                        
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className="mb-3 bg-gradient-blumine-21  transition-transform transform hover:animate-tilt">
                      <Card.Body>
                        <h2 className="text-white">Desarrollo de Apps</h2>
                        <p className="text-white bg-white-transparent-01 p-2 rounded-lg">
                          Desarrollamos aplicaciones móviles atractivas y
                          fáciles de usar para todas las plataformas, desde la
                          conceptualización hasta el lanzamiento, adaptadas a
                          las necesidades específicas de sus usuarios.
                        </p>
                        <div className="d-flex d-inline-block">
                          <DiAndroid color="white" size={30}/>
                          <DiApple color="white" size={30}/> 
                          <DiWindows color="white" size={30}/> 
                        </div>   
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Card className="mb-3 bg-gradient-blumine-21  transition-transform transform hover:animate-tilt">
                      <Card.Body>
                        <h2 className="text-white">Marketing Digital</h2>
                        <p className="text-white bg-white-transparent-01 p-2 rounded-lg">
                          Mejoramos la visibilidad en motores de búsqueda
                          mediante estrategias de SEO y SEM basadas en datos,
                          aumentando el tráfico orgánico y pagado para convertir
                          visitas en clientes y destacar en un mercado
                          competitivo.
                        </p>
                        <div className="d-flex d-inline-block">                                                    
                          <BsBadgeAdFill  className="pe-1" color="white" size={28}/>
                          <BsChatQuote className="pe-1" color="white" size={28} /> 
                          <BsBarChartFill  className="pe-1" color="white" size={28}/>
                        </div>                           
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className="mb-3 bg-gradient-blumine-21 transition-transform transform hover:animate-tilt">
                      <Card.Body>
                        <h2 className="text-white">Consultoría</h2>
                        <p className="text-white bg-white-transparent-01 p-2 rounded-lg">
                          Ofrecemos servicios de consultoría tecnológica para
                          ayudarle a identificar oportunidades, optimizar
                          procesos y aplicar las mejores prácticas en su
                          negocio, asegurando un crecimiento sostenido y
                          eficiente.
                        </p>
                        <div className="d-flex d-inline-block">                       
                          <BsAward className="pe-1" color="white" size={30}/>
                          <BsUiChecks className="pe-1" color="white" size={30}/> 
                          <BsFileText className="pe-1" color="white" size={30}/>
                        </div>    
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <ImageGrid images={images} />
      </Container>
      <FloatingButton />
    </>
  );
};

export default MyBody;
