import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import '../styles/CookieConsentBanner.css'; // Asegúrate de crear y estilizar este archivo CSS

const CookieConsentBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [preferences, setPreferences] = useState({
    functional: true,
    preferences: false,
    statistics: false,
    marketing: false,
  });

  useEffect(() => {
    const consentGiven = Cookies.get('user-consent');
    if (consentGiven) {
      setIsVisible(false);
    }
  }, []);

  const handleToggle = (category) => {
    setPreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleAccept = () => {
    Object.keys(preferences).forEach((category) => {
      Cookies.set(category, preferences[category], { path: '/', expires: 30 });
    });
    Cookies.set('user-consent', true, { path: '/', expires: 30 });
    console.log('Cookies preferences accepted:', preferences);
    setIsVisible(false); // Ocultar el banner
    setShowModal(false);
  };

  const handleDecline = () => {
    Object.keys(preferences).forEach((category) => {
      Cookies.set(category, false, { path: '/', expires: 30 });
    });
    Cookies.set('user-consent', false, { path: '/', expires: 30 });
    console.log('Cookies preferences declined');
    setIsVisible(false); // Ocultar el banner
    setShowModal(false);
  };

  const handleSavePreferences = () => {
    Object.keys(preferences).forEach((category) => {
      Cookies.set(category, preferences[category], { path: '/', expires: 30 });
    });
    Cookies.set('user-consent', true, { path: '/', expires: 30 });
    console.log('Cookies preferences saved:', preferences);    
    setShowModal(false);
    setIsVisible(false); // Ocultar el banner
  };

  return (
    <>
      {isVisible && (
        <CookieConsent 
          contentClasses="myNav p-2 m-0"
          location="bottom"
          cookieName="user-consent"
          style={{
            background: "#ffffff",
            color: "#000000",
            fontSize: "14px",
            padding: "0",
            borderTop: "0px solid #ddd",
          }}
          buttonStyle={{
            display: "none",
          }}
        >
          <div id="myCookieBanner" className="cookie-banner-content myNav">
            <Row>
              <Col md={9}>
                <p>Utilizamos cookies propias y de terceros para fines analíticos y para ofrecerle servicios adecuados a su perfil, así como publicidad propia y de terceros. La base de tratamiento es el consentimiento, salvo en el caso de las cookies imprescindibles para el correcto funcionamiento del sitio web. Puede obtener más información en nuestra Política de Cookies, aceptar todas las cookies pulsando el botón “ACEPTAR” o configurarlas o rechazar su uso pulsando el botón “CONFIGURAR”.</p>
              </Col>
              <Col md={3}>
                <div className="cookie-buttons">
                  <Button className='bg-gradient-blumine-diagonal' aria-label='aceptar' variant="success" onClick={handleAccept}>Aceptar</Button>
                  <Button className='bg-gradient-blumine-diagonal' aria-label='configurar' variant="secondary" onClick={() => setShowModal(true)}>Configurar</Button>
                  <Button className='bg-gradient-blumine-diagonal' aria-label='cancelar' variant="danger" onClick={handleDecline}>Cancelar</Button>
                </div>
              </Col>
            </Row>          
          </div>
        </CookieConsent>
      )}
   
      <Modal  show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className='myNav'>
          <Modal.Title>Gestionar el consentimiento de las cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body className='myNav'>
          <p>
            Utilizamos tecnologías como las cookies para almacenar y/o acceder a la información del dispositivo. Lo hacemos para mejorar la experiencia de navegación y para mostrar anuncios (no) personalizados.
          </p>
          <div className="cookie-preferences myNav">
            <div className="cookie-category">
              <span>Funcional</span>
              <span>Siempre activo</span>
            </div>
            <div className="cookie-category">
              <span>Preferencias</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={preferences.preferences}
                  onChange={() => handleToggle('preferences')}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="cookie-category">
              <span>Estadísticas</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={preferences.statistics}
                  onChange={() => handleToggle('statistics')}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="cookie-category">
              <span>Marketing</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={() => handleToggle('marketing')}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className='myNav mt-5 mb-2 flex items-center justify-between'>
            <Button className='bg-gradient-blumine-diagonal' aria-label='aceptar' variant="success" onClick={handleSavePreferences}>Guardar preferencias</Button>
            <Button className='bg-gradient-blumine-diagonal' aria-label='cerrar' variant="danger" onClick={() => setShowModal(false)}>Cerrar</Button>
          </div>
        </Modal.Body>       
      </Modal>
    </>
  );
};

export default CookieConsentBanner;
